<template>
  <div>
    <c-table
      ref="table"
      :title="popupParam.workName"
      :columns="grid.columns"
      :data="grid.data"
      gridHeight="600px"
      :usePaging="false"
      :columnSetting="false"
      @linkClick="linkClick"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='workEndFlag'">
          <q-chip
            :color="getColors(props.row.workEndFlag)"
            text-color="white"
            :clickable="true"
            @click="openDetail(props.row)">
            {{props.row.workEndFlagName}}
          </q-chip>
        </template>
        <template v-if="col.name === 'userLists'">
          <c-tag 
            :isAdd="false"
            :editable="editable"
            icon="person_outline"
            itemText="workUserName"
            itemValue="check"
            name="userLists" 
            v-model="props.row.userLists"
          />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        workResultId: '',
        workResultWorkId: '',
        workOprTypeCd: '',
        workName: '',
        detailFlag: 'Y'
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      column1: [
        {
          name: 'workDt',
          field: 'workDt',
          label: '작업일',
          align: 'center',
          style: 'width:100px',
          sortable: true,
        },
        {
          name: 'workEndFlag',
          field: 'workEndFlag',
          label: '작업결과',
          align: 'center',
          type: 'custom',
          style: 'width:100px',
          sortable: true,
        },
        {
          name: 'workContent',
          field: 'workContent',
          label: '작업내역',
          align: 'left',
          sortable: true,
        },
        {
          name: 'workpermitTypesName',
          field: 'workpermitTypesName',
          label: '작업허가서 구분',
          align: 'center',
          style: 'width:200px',
          sortable: true,
        },
        {
          name: 'permitNo',
          field: 'permitNo',
          label: '작업허가서',
          align: 'center',
          style: 'width:140px',
          type: 'link',
          sortable: true,
        },
        {
          name: 'workRiskFactorsName',
          field: 'workRiskFactorsName',
          label: '위험요인',
          align: 'center',
          style: 'width:200px',
          sortable: true,
        },
        {
          name: 'userLists',
          field: 'userLists',
          label: '내부작업자',
          align: 'center',
          type: 'custom',
          style: 'width:360px',
          sortable: true,
        },
      ],
      column2: [
        {
          name: 'workDt',
          field: 'workDt',
          label: '작업일',
          align: 'center',
          style: 'width:100px',
          sortable: true,
        },
        {
          name: 'workEndFlag',
          field: 'workEndFlag',
          label: '작업결과',
          align: 'center',
          type: 'custom',
          style: 'width:100px',
          sortable: true,
        },
        {
          name: 'workContent',
          field: 'workContent',
          label: '작업내역',
          align: 'left',
          sortable: true,
        },
        {
          name: 'workpermitTypesName',
          field: 'workpermitTypesName',
          label: '작업허가서 구분',
          align: 'center',
          style: 'width:200px',
          sortable: true,
        },
        {
          name: 'permitNo',
          field: 'permitNo',
          label: '작업허가서',
          align: 'center',
          style: 'width:140px',
          type: 'link',
          sortable: true,
        },
        {
          name: 'workRiskFactorsName',
          field: 'workRiskFactorsName',
          label: '위험요인',
          align: 'center',
          style: 'width:200px',
          sortable: true,
        },
        {
          name: 'vendorName',
          field: 'vendorName',
          label: '협력업체',
          align: 'center',
          style: 'width:140px',
          sortable: true,
        },
        {
          name: 'vendorWorkerCnt',
          field: 'vendorWorkerCnt',
          label: '작업 인원',
          align: 'center',
          style: 'width:100px',
          sortable: true,
        },
      ],
      grid: {
        columns: [],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.wod.workorder.daily.list.url;
      if (this.popupParam.workOprTypeCd == 'WOTC000001') {
        this.grid.columns = this.column1;
      } else {
        this.grid.columns = this.column2;
      }
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.popupParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getColors(_code) {
      var returnColor = '';
      switch(_code) {
        case 'WDRC000010': // 작업완료
          returnColor = 'blue';
          break;
        case 'WDRC000009': // 작업취소
          returnColor = 'red';
          break;
        case 'WDRC000001': // 작업결과입력
          returnColor = 'orange';
          break;
        case 'WDRC000002': // 작업결과입력
          returnColor = 'green';
          break;
      }

      return returnColor;
    },
    openDetail(row) {
      this.popupOptions.title = '일일작업 결과';
      this.popupOptions.isFull = false;
      this.popupOptions.width = '99%';
      this.popupOptions.param = {
        workResultWorkId: row ? row.workResultWorkId : '',
        workDt: row ? row.workDt : '',
        workResultDailyId: row ? row.workResultDailyId : '',
      };
      this.popupOptions.target = () => import(`${'./workOrderDailyPlanDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    linkClick(row) {
      this.popupOptions.title = '작업허가서 상세';
      this.popupOptions.isFull = true;
      this.popupOptions.param = {
        sopWorkPermitId: row.sopWorkPermitId,
        permitTypeCd: row.permitTypeCd,
      };
      this.popupOptions.isFull = true;
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopupSwp;
    },
    closePopupSwp() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
